import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import FeatureHeader from "../components/sections/feature-header"
import FeatureLinks from "../components/sections/feature-links"
import Footer from "../components/sections/footer"

const headline = "Konfigurator für Ihre Erstausstattung"
const text =
  "Für den Einzug Ihres Welpen benötigen Sie viele Dinge z.B. Halsband, Leine, Transportbox, Hundebett, Futter, Napf, Spielzeug, etc. Unser WelpenOrganizer hilft Ihnen bei der Auswahl der richtigen Produkte für eine individuelle Erstausstattung nach den Bedürfnissen von Ihnen und Ihrem Welpen."

const Erstausstattung = () => {
  return (
    <Layout>
      <SEO title={headline} />
      <Navigation />
      <FeatureHeader page={"erstausstattung"} headline={headline} text={text} />
      <FeatureLinks />
      <Footer />
    </Layout>
  )
}

export default Erstausstattung
